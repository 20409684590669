import React, { Component } from 'react';

import Globals from '../Globals';
import moment from 'moment'
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { SetNestedData, GetNestedData } from '../Utils';
var lodash = require('lodash')

class VehiculeParcForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vehicule: {
        "Famille": "",
        "Gamme": "",
        "Marque": "",
        "Modele": "",
        "Version": "",
        "Annee": "",
        "DateMEC": "",
        "Immatriculation": "",
        "DateImmatriculation": "",
        "Kilometrage": "",
        "DateReleveKilometrage": "",
        "VIN": "",
        "Commentaire": "",
        "PneusType": "",
        "PneusAV": "",
        "PneusAR": "",
        "Moteur": {
          "PuissanceFiscale": "",
          "PuissanceCV": "",
          "CM3": "",
          "NbCylindres": "",
          "Energie": "",
          "BoiteDeVitesse": "",
          "EmissionCO2": "",
          "ConsommationMixte": "",
          "ConsommationUrbaine": "",
          "ConsommationExtraUrbaine": "",
          "PuissanceKW": "",
          "RegimeMoteur": ""
        },
        "Dimensions": {
          "Longueur": "",
          "Largeur": "",
          "Hauteur": "",
          "PoidsVide": "",
          "PTAC": "",
          "ChargeUtile": ""
        },
        "SpecifiqueAgri": {
          "DebitHuile": "",
          "ForceLevage": "",
          "HauteurLevage": "",
          "Vitesse": "",
          "LargeurTravail": "",
          "LargeurCoupe": "",
          "LargeurRamassage": "",
          "NbRangs": "",
          "Rendement": "",
          "PuissancePDF": "",
          "VitessePDF": "",
          "Capacite": ""
        },
        "SpecifiqueLoisir": {
          "NbPlacesCouchage":"",
          "TypeCouchage":"",
          "ReservoirEauPropre":"",
          "ReservoirEauUsee":"",
          "NumeroCellule":"",
          "PorteurMarque":"",
          "PorteurModele":"",
          "Collection":"",
        },
        "Financement": {
          "IDMatTypeF": "",
          "Descr": "",
          "Debut": "",
          "Fin": "",
          "DureeMois": ""
        },
        "ChampsPersonnalises" : [],
        "IDFicheCat": null,
      },

      fetchFamillesInProgress: false,
      fetchGammesInProgress: false,
      fetchMarquesInProgress: false,
      fetchModelesInProgress: false,
      fetchVersionsInProgress: false,
      fetchFieldsInProgress: false,
      fetchTypesFinancementInProgress: false,

      saveInProgress: false,
      isEdit: false,

      familles:[],
      gammes:[],
      marques:[],
      modeles:[],
      versions:[],
      typesLits:[],
      typesFinancement: [],
      
      saisieFamilleMan: false,
      saisieGammeMan: false,
      saisieMarqueMan: false,
      saisieModeleMan: false,
      fields: null,
    }

    this.IsVDL = localStorage.IsVDL === 'true';
    this.IsAgri = localStorage.IsAgri === 'true';
    this.IsMoto = localStorage.IsMoto === 'true';

    this.categoriesChamps = {
      'gestion':'Gestion',
      'tech':'Infos techniques',
      '_perso_':'Autres infos',
    };
  }

  componentDidMount() {
    if(this.props.vehicule) {
      console.log(this.props.vehicule)
      var vehicule = this.props.vehicule;
      if(vehicule.DateImmatriculation && vehicule.DateImmatriculation.indexOf('/') === -1)vehicule.DateImmatriculation = moment(vehicule.DateImmatriculation).format("DD/MM/YYYY")
      if(vehicule.DateMEC && vehicule.DateMEC.indexOf('/') === -1)vehicule.DateMEC = moment(vehicule.DateMEC).format("DD/MM/YYYY")
      if(vehicule.DateReleveKilometrage && vehicule.DateReleveKilometrage.indexOf('/') === -1)vehicule.DateReleveKilometrage = moment(vehicule.DateReleveKilometrage).format("DD/MM/YYYY")
      if(vehicule.Financement.Fin && vehicule.Financement.Fin.indexOf('/') === -1)vehicule.Financement.Fin = moment(vehicule.Financement.Fin).format("DD/MM/YYYY")
      if(!vehicule.DateImmatriculation)vehicule.DateImmatriculation = "";
      if(!vehicule.DateMEC)vehicule.DateMEC = "";
      if(!vehicule.DateReleveKilometrage)vehicule.DateReleveKilometrage = "";
      this.setState({
        vehicule: this.props.vehicule,
        isEdit: true,
      }, () => {
      	if(this.IsAgri)
        	this.loadFields()
      })
    }
    else {
      this.loadMarques();
      if(this.IsVDL || this.IsAgri){
        this.loadFamilles();
      }
    }

    if(this.IsVDL){
      this.loadTypesLits();
    }
    if(this.IsAgri){
      this.loadTypesFinancement();
    }
  }

  /**
   * Chargement des familles de matériel
   */
  loadFamilles(){
    this.setState({
      fetchFamillesInProgress: true
    })

    fetch(localStorage.ClientApiURL + '/referentiel/familles', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var familles = JSON.parse(json);
    
      this.setState({
        familles: familles,
        fetchFamillesInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchFamillesInProgress: false })
    });
  }

  /**
   * Chargement des gammes de matériels suivant la famille sélectionnée
   */
  loadGammes() {
    this.setState({
      gammes: [],
      fetchGammesInProgress: true,
    })

    var famille = lodash.find(this.state.familles, {Descr: this.state.vehicule.Famille}).IDFamille;

    if(!famille){
      this.setState({
        fetchGammesInProgress: false
      })
      return;
    }
    
    fetch(localStorage.ClientApiURL+'/referentiel/gammes?famille='+famille, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        gammes: JSON.parse(json),
        fetchGammesInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchGammesInProgress: false })
    });
  }
  
  /**
   * Chargement des marques de matériels
   */
  loadMarques() {
    this.setState({
      fetchMarquesInProgress: true
    })

    fetch(localStorage.ClientApiURL + '/referentiel/marques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var marques = JSON.parse(json);
      if(this.IsVDL || this.IsAgri || this.IsMoto){
        marques = lodash.each(lodash.uniqBy(marques, "Descr"), (marque) => {
            marque.Marque = marque.Descr;
        })
        if(this.IsVDL)
        	marques.unshift({Marque:'Saisir une marque'})
      }

      this.setState({
        marques: marques,
        fetchMarquesInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchMarquesInProgress: false })
    });
  }

  /**
   * Chargement des modèles en fonction de la marque sélectionnée
   */
  loadModeles() {
    this.setState({
      fetchModelesInProgress: true
    })

    var marque;
    if(this.IsVDL || this.IsAgri || this.IsMoto) {
      marque = lodash.find(this.state.marques, {Descr: this.state.vehicule.Marque}).IDMarque;
    }
    else {
      marque = this.state.vehicule.Marque;
    }

    fetch(localStorage.ClientApiURL+'/referentiel/modeles?marque='+marque, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var modeles = JSON.parse(json);

      if(this.IsVDL || this.IsAgri || this.IsMoto){
        modeles = lodash.map(modeles, (modele) => {
            modele.Modele = modele.Descr;
            return modele;
        })
        if(this.IsVDL)
        	modeles.unshift({Modele:'Saisir un modèle'})
      }

      this.setState({
        modeles: modeles,
        fetchModelesInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchModelesInProgress: false })
    });
  }

  /**
   * Chargement des versions 
   * - Si VDL : En fonction des famille/gamme/marque/modèle sélectionnés
   * - Sinon : En fonction de la marque et du modèle uniquement
   * Non utilisé pour l'agri
   */
  loadVersions() {
    this.setState({
      fetchVersionsInProgress: true
    })

    if(this.IsVDL){
      let _famille = lodash.find(this.state.familles, {Descr: this.state.vehicule.Famille});
      let famille = _famille ? _famille.IDFamille : '';
      let _gamme = lodash.find(this.state.gammes, {Descr: this.state.vehicule.Gamme});
      let gamme = _gamme ? _gamme.IDGamme : '';
      let _marque = lodash.find(this.state.marques, {Descr: this.state.vehicule.Marque});
      let marque = _marque ? _marque.IDMarque : '';
      let _modele = lodash.find(this.state.modeles, {Descr: this.state.vehicule.Modele});
      let modele = _modele ? _modele.IDModele : '';
      var url = '/referentiel/versions?famille='+famille+'&gamme='+gamme+'&marque='+marque+'&modele='+modele;
    }
    else 
      var url = '/referentiel/vehicules?search='+ this.state.vehicule.Marque+' '+this.state.vehicule.Modele;

    fetch(localStorage.ClientApiURL+url , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      //console.log(json)
      var versions = JSON.parse(json);
      if(this.IsVDL){
        versions.unshift({IDVersion:'Saisir une version', Descr:'Saisir une version'});
      }

      this.setState({
        versions: versions,
        fetchVersionsInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchVersionsInProgress: false })
    });
  }

  /**
   * VDL : Chargement des types de lits
   */
  loadTypesLits() {
    this.setState({
      fetchTypesLitsInProgress: true
    })

    fetch(localStorage.ClientApiURL + '/vehicules/typesLits', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var typesLits = JSON.parse(json);
    
      this.setState({
        typesLits: typesLits,
        fetchTypesLitsInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchTypesLitsInProgress: false })
    });
  }
  
  /**
   * Agri : chargement des champs en fonction de la famille du véhicule
   */
  loadFields() {
    if(!this.state.vehicule.Famille) {
      this.setState({
        fields: null
      })
      return;
    }

    this.setState({
      fetchFieldsInProgress: true
    })

    fetch(localStorage.ClientApiURL+'/agri/familles/'+this.state.vehicule.Famille+'/champs' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var fields = JSON.parse(json);
      if(fields.length == 0)
        fields = null;
      else 
        fields = lodash.groupBy(lodash.orderBy(fields, 'Descr'), f => {
            return f.Categorie ? f.Categorie : '_perso_'
        });
      this.setState({
        fields: fields,
        fetchFieldsInProgress: false,
      }, () => {console.log(this.state.fields)});
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchFieldsInProgress: false })
    });
  }
  
  /**
   * Agri : Chargement des types de financement
   */
  loadTypesFinancement(){
    this.setState({
      fetchTypesFinancementInProgress: true
    })

    fetch(localStorage.ClientApiURL+'/vehicules/typesFinancement' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        typesFinancement: JSON.parse(json),
        fetchTypesFinancementInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchTypesFinancementInProgress: false })
    });
  }

  /**
   * Sélection de la famille 
   * @param {string} famille
   */
  selectFamille(famille){
    var vehicule = this.state.vehicule;

    if(famille === "_SAISIE-MANUELLE_"){
      vehicule.Famille = '';
      vehicule.Gamme = '';
      this.setState({
        saisieFamilleMan: true,
        vehicule: vehicule
      }, () => {
        this.refs.saisieFamilleMan.focus();
      })
      return;
    }
    
    vehicule.Famille = famille;
    vehicule.Gamme = '';
    this.setState({
      vehicule:vehicule
    }, () => {
    	this.loadGammes();
    	if(this.IsAgri) //Si Agri on recharge les champs vu qu'ils dépendent de la famille
    		this.loadFields();
    });
  }

  /**
   * Sélection de la gamme
   * @param {string} gamme 
   */
  selectGamme(gamme){
    var vehicule = this.state.vehicule;

    if(gamme === "_SAISIE-MANUELLE_"){
      vehicule.Gamme = '';
      this.setState({
        saisieGammeMan: true,
        vehicule: vehicule
      }, () => {
        this.refs.saisieGammeMan.focus();
      })
      return;
    }

    vehicule.Gamme = gamme;
    this.setState({
      vehicule:vehicule
    });
  }

  /**
   * Sélection de la marque
   * @param {string} marque 
   */
  selectMarque(marque){
    var vehicule = this.state.vehicule;

    if(marque === "Saisir une marque" || marque === "_SAISIE-MANUELLE_"){
      vehicule.Marque = '';
      vehicule.Modele = '';
      this.setState({
        saisieMarqueMan: true,
        saisieModeleMan: true,
        saisieVersionMan: true,
        vehicule: vehicule
      }, () => {
        this.refs.saisieMarqueMan.focus();
      })
      return;
    }

    vehicule.Marque = marque;
    vehicule.Modele = '';
    vehicule.Version = '';
    vehicule.IDFicheCat = null;
    this.setState({
      vehicule:vehicule
    }, () => this.loadModeles());
  }

  /**
   * Sélection du modèle
   * @param {string} modele 
   */
  selectModele(modele){
    var vehicule = this.state.vehicule;

    if(modele === "Saisir un modèle" || modele === "_SAISIE-MANUELLE_"){
      vehicule.Modele = '';
      this.setState({
        saisieModeleMan: true,
        saisieVersionMan: true,
        vehicule: vehicule,
      }, () => {
        this.refs.saisieModeleMan.focus();
      })
      return;
    }

    vehicule.Modele = modele;
    vehicule.Version = '';
    vehicule.IDFicheCat = null;
    this.setState({
      vehicule: vehicule
    }, () => {
      if(!this.IsAgri) //Versions non utilisées dans l'Agri
        this.loadVersions()
    })
  }

  /**
   * Sélection de la version
   * Non utilisé dans l'Agri où la version n'est saisie que manuellement
   * @param {int|string} version. Si Auto correspond à l'IDVehicule dans le catalogue VMAuto, sinon à la version en texte 
   */
  selectVersion(version){
    var vehicule = this.state.vehicule;

    if(version === "Saisir une version"){
      vehicule.Version = '';
      this.setState({
        saisieVersionMan: true,
        vehicule: vehicule,
      }, () => {
        this.refs.saisieVersionMan.focus();
      })
      return;
    }

    if(this.IsVDL){
      vehicule.Version = version;
    }
    else { //Si Auto on définit l'IDFicheCat à partir de l'IDVehicule du catalogue VMAuto
      var selectedVersion = lodash.find(this.state.versions, (v) => {
        return v.IDVehicule == version;
      });
      vehicule.Version = selectedVersion.Version;
      vehicule.Gamme = selectedVersion.Categorie;
      vehicule.IDFicheCat = selectedVersion.IDVehicule
      console.log(vehicule)
    }

    this.setState({
      vehicule: vehicule
    })
  }
  
  /**
   * Agri : Modification d'un champ personnalisé
   * @param {int} IDFieldMat 
   * @param {*} value 
   */
  setChampPersonnalise(IDFieldMat, value){
    var vehicule = this.state.vehicule;
    var champsPersonnalises = vehicule.ChampsPersonnalises;
    var idx = lodash.findIndex(champsPersonnalises, {IDFieldMat: IDFieldMat});
    //console.log(idx)
    if(idx != -1){
      champsPersonnalises[idx].Value = value;
    }
    else {
      champsPersonnalises.push({
        IDFieldMat: IDFieldMat,
        Value: value,
      })
    }
    //console.log(champsPersonnalises)
    vehicule.ChampsPersonnalises = champsPersonnalises;
    this.setState({
      vehicule: vehicule
    })
  } 

  /**
   * Agri : Récupération de la valeur d'un champ personnalisé
   * @param {int} IDFieldMat 
   */
  getChampPersonnaliseValue(IDFieldMat){
    var champ = lodash.find(this.state.vehicule.ChampsPersonnalises, {IDFieldMat: IDFieldMat});
    if(champ)
      return champ.Value;
    else 
      return "";
  }

  /**
   * Enregistrement du matériel aussi bien en création qu'en modif (dépend de this.state.isEdit)
   * @param {event} event 
   */
  onSubmit(event) {
    event.preventDefault();

    this.setState({
      saveInProgress: true,
    })

    var body = this.state.vehicule;

    if(this.props.IDProspect === null){
      if(this.state.isEdit){
        this.props.finishEditVehicule(body);
      }
      else {
        this.props.finishCreateVehicule(body);
      }
      return;
    }

    body = JSON.stringify(body);

    var url = '', method = '';
    if(this.state.isEdit){
      url = localStorage.ClientApiURL + '/prospects/'+ this.props.IDProspect +'/vehicules/'+this.state.vehicule.IDVehicule;
      method = 'PUT'
    }
    else {
      url = localStorage.ClientApiURL+'/prospects/'+ this.props.IDProspect +'/vehicules';
      method = 'POST'
    }

    fetch(url , {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      if(this.state.isEdit)
        this.props.finishEditVehicule(this.state.vehicule.IDVehicule);
      else 
        this.props.finishCreateVehicule(json.IDVehicule);
    })
    .catch((error) => {
      console.log(error)
      this.setState({ saveInProgress: false })
    });
  }

  /**
   * Vérifie si le bouton d'enregistrement doit être désactivé
   */
  disabledButtonSave() {
    if (this.state.vehicule.Marque == '' || this.state.vehicule.Modele == '' || this.state.saveInProgress) {
      return true;
    }

    if(this.IsAgri && (this.state.vehicule.Famille == '' || this.state.vehicule.Gamme == '')){
      return true;
    }

    return false;
  }

  /**
   * Modification de la valeur d'un champ, sauf champ personnalisé Agri (voir fonction setChampPersonnalise()
   * @param {event} event 
   */
  onChangeForm(event) {
    var vehicule = this.state.vehicule;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setVehiculeProp(vehicule, event.target.name, value)
    this.setState({
      vehicule: vehicule,
    })
  }

  /**
   * Récupération de la valeur d'une propriété du véhicule
   * Gère les propriétés imbriquées, par exemple getVehiculeProp("Dimensions.Hauteur")
   * @param {string} prop 
   * @param {string} type 
   */
  getVehiculeProp(prop, type){
    var value = GetNestedData(this.state.vehicule, prop);
    if(type === "date" && value.indexOf('/') === -1 && value != "")
      return moment(value).format("DD/MM/YYYY")
    
    return value;
  }

  /**
   * Modifie la valeur d'une propriété du véhicule.
   * Gère les propriétés imbriquées, par exemple setVehiculeProp(vehicule, "Dimensions.Hauteur", 4)
   * @param {object} vehicule (en principe à partir de this.state.vehicule)
   * @param {string} prop 
   * @param {*} value 
   */
  setVehiculeProp(vehicule, prop, value){
    SetNestedData(vehicule, prop, value);
  }

  /**
   * Retourne le pattern/mask pour les <InputMask/> ou <input> en fonction du type de données
   * @param {string} type 
   */
  getInputPattern(type){
    switch(type){
      case 'int':
        return "[0-9]+"
      case 'double':
        return "[0-9]+(\.[0-9][0-9]*)?"
      case 'date':
        return "99/99/9999"
      case 'immat':
        return "**-***-**"
      default:
        return null;
    }
  }

  /**
   * Render si Non-Agri.
   */
  renderDefault(){
    return (
      <div className='container-fluid form-vehicule'>
        <div className='row'>
          <div className='col-md-12'>
            <div>
              <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>
{/****************************** Famille / Gamme **********************************/}
              {this.IsVDL ? 
                <div className="form-group">
                  <div className="col-md-4">
                    <label className="col-md-4 control-label">Famille</label>
                    <div className="col-md-8">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Famille}</p>
                    :
                      this.state.saisieFamilleMan ?
                        <input type="text" name="Famille" className="form-control" ref="saisieFamilleMan" onChange={this.onChangeForm.bind(this)} />
                      :
                        <select style={{width:'100%'}} onChange={(e)=>this.selectFamille(e.target.value)}>
                          <option value=''>
                            {this.state.fetchFamillesInProgress ? 'Chargement des familles...' : 'Sélectionner une famille'}
                          </option>
                          <option value="_SAISIE-MANUELLE_">Saisir une famille</option>
                          {this.state.familles.map((famille) => {
                            return <option key={famille.Descr} value={famille.Descr}>{famille.Descr}</option>;
                          })}
                        </select>
                    }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-md-4 control-label">Gamme</label>
                    <div className="col-md-8">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Gamme}</p>
                    :
                      this.state.saisieGammeMan ?
                        <input type="text" name="Gamme" className="form-control" ref="saisieGammeMan" onChange={this.onChangeForm.bind(this)} />
                      :
                        <select style={{width:'100%'}} onChange={(e)=>this.selectGamme(e.target.value)}>
                          <option value=''>
                            {this.state.fetchGammesInProgress ? 'Chargement des gammes...' : 'Sélectionner une gamme'}
                          </option>
                          <option value="_SAISIE-MANUELLE_">Saisir une gamme</option>
                          {this.state.gammes.map((gamme) => {
                            return <option key={gamme.Descr} value={gamme.Descr}>{gamme.Descr}</option>;
                          })}
                        </select>
                    }

                    </div>
                  </div>
                </div>
              :null}

{/******************* Marque / Modèle / Version *******************/}
                <div className="form-group">
                  <div className="col-md-4">
                      <label className="col-md-4 control-label">Marque</label>
                      <div className="col-md-8">
                      {this.state.isEdit ? 
                        <p className="form-control-static">{this.state.vehicule.Marque}</p>
                      :
                        this.state.saisieMarqueMan ?
                          <input type="text" name="Marque" className="form-control" ref="saisieMarqueMan" onChange={this.onChangeForm.bind(this)} />
                        :
                          <Select options={this.state.marques.map((marque) => {
                              return {value:marque.Marque, label:marque.Marque};
                            })} 
                            placeholder={this.state.fetchMarquesInProgress ? 'Chargement des marques...' : 'Sélectionner une marque'}
                            value={this.state.vehicule.Marque}
                            clearable={false}
                            noResultsText="Aucun résultat"
                            arrowRenderer={null}
                            onChange={(value)=>this.selectMarque(value.value)}/>
                      }
                      </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-md-4 control-label">Modèle</label>
                    <div className="col-md-8">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Modele}</p>
                    :
                      this.state.saisieModeleMan ?
                        <input type="text" name="Modele" className="form-control" ref="saisieModeleMan" onChange={this.onChangeForm.bind(this)} />
                        : 
                        <Select options={this.state.modeles.map((modele) => {
                            return {value:modele.Modele, label:modele.Modele};
                          })} 
                          placeholder={this.state.fetchModelesInProgress ? 'Chargement des modèles...' : 'Sélectionner un modèle'}
                          value={this.state.vehicule.Modele}
                          clearable={false}
                          noResultsText="Aucun résultat"
                          arrowRenderer={null}
                          onChange={(value)=>this.selectModele(value.value)}/>
                    }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className={(this.IsVDL ? "col-md-2" : "col-md-4") + " control-label"}>Version</label>
                    <div className={(this.IsVDL ? "col-md-4" : "col-md-8")}>
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Version}</p>
                    :
                      this.state.saisieVersionMan ?
                        <input type="text" name="Version" ref="saisieVersionMan" className="form-control" onChange={this.onChangeForm.bind(this)} />
                      : 
                        <Select options={this.state.versions.map((version) => {
                            if(this.IsVDL)
                              return {value:version.Descr, label:version.Descr};
                            else 
                              return {value:version.IDVehicule, label:version.Version+' '+moment(version.DebutProduction).format('DD/MM/YYYY')+' -> '+(version.FinProduction ? moment(version.FinProduction).format('DD/MM/YYYY') : '-')};
                          })} 
                          placeholder={this.state.fetchVersionsInProgress ? 'Chargement des versions...' : 'Sélectionner une version'}
                          value={this.IsVDL ? this.state.vehicule.Version : this.state.vehicule.IDFicheCat}
                          clearable={false}
                          noResultsText="Aucun résultat"
                          arrowRenderer={null}
                          onChange={(value)=>this.selectVersion(value.value)}/>
                    }
                    </div>
                    {this.IsVDL ?
                      [
                        <label className="col-md-2 control-label" key="collection_label">Collection</label>,
                        <div className="col-md-4" key="collection_input">
                          <input type="text" name="SpecifiqueLoisir.Collection" value={this.getVehiculeProp("SpecifiqueLoisir.Collection")} className="form-control" onChange={this.onChangeForm.bind(this)} maxLength={4} />
                        </div>
                      ]
                    :null}
                  </div>
                </div>
{/******************************* VDL : Type de lits ***************************/}
                {this.IsVDL ? 
                  <div className="form-group">
                    <div className="col-md-4">
                      <label className="col-md-4 control-label">Type de lits</label>
                      <div className="col-md-8">
                        <select style={{width:'100%'}} name="SpecifiqueLoisir.IDImplantation" onChange={this.onChangeForm.bind(this)} value={this.getVehiculeProp("SpecifiqueLoisir.IDImplantation")}>
                          <option value=''>
                            {this.state.fetchTypesLitsInProgress ? 'Chargement...' : ''}
                          </option>
                          {this.state.typesLits.map((tl) => {
                            return <option key={tl.IDImplantation} value={tl.IDImplantation}>{tl.Libelle}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                :null}

{/****************************** Champs communs tous véhicules/matériels non Agri **********************************/}
                {this.state.vehicule.Marque != '' && this.state.vehicule.Modele != '' 
                  && (!this.IsVDL || (this.state.vehicule.Famille != '' && this.state.vehicule.Gamme != '')) ? 
                  <div style={{borderTop:'1px solid #CBCBCB'}}>
                    <div className="form-group">
                      <div className="col-md-4">
                        <label className="col-md-4 control-label">1ère mise en circ.</label>
                        <div className="col-md-8">
                          <InputMask mask="99/99/9999" className="form-control" name="DateMEC" value={this.state.vehicule.DateMEC} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Immatriculation</label>
                        <div className="col-md-8">
                          <InputMask mask="**-***-**" className="form-control" name="Immatriculation" value={this.state.vehicule.Immatriculation} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="col-md-4 control-label text-nowrap">Date immatriculation</label>
                        <div className="col-md-8">
                          <InputMask mask="99/99/9999" className="form-control" name="DateImmatriculation" value={this.state.vehicule.DateImmatriculation} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Numéro de série</label>
                        <div className="col-md-8">
                          <input type="text" className="form-control" name="VIN" value={this.state.vehicule.VIN} onChange={this.onChangeForm.bind(this)} maxLength="17" />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Kilométrage</label>
                        <div className="col-md-8">
                          <input type="text" className="form-control" name="Kilometrage" value={this.state.vehicule.Kilometrage} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>
                    
                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Date de relevé</label>
                        <div className="col-md-8">
                          <InputMask mask="99/99/9999" className="form-control" name="DateReleveKilometrage" value={this.state.vehicule.DateReleveKilometrage} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>
                    </div>

{/****************************** VDL : Champs spécifiques **********************************/}
                    {this.IsVDL ?
                      <div style={{borderTop:'1px solid #CBCBCB'}}>
                        <div style={{padding:'15px 0 0 15px'}}>Porteur</div>
                        <div className="form-group">
                          <div className="col-md-4">
                            <label className="col-md-4 control-label">Marque</label>
                            <div className="col-md-8">
                              <input type="text" className="form-control" name="SpecifiqueLoisir.PorteurMarque" value={this.getVehiculeProp("SpecifiqueLoisir.PorteurMarque")} onChange={this.onChangeForm.bind(this)} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="col-md-4 control-label">Modele</label>
                            <div className="col-md-8">
                              <input type="text" className="form-control" name="SpecifiqueLoisir.PorteurModele" value={this.getVehiculeProp("SpecifiqueLoisir.PorteurModele")} onChange={this.onChangeForm.bind(this)} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="col-md-3 control-label text-nowrap">Cylindrée (L)</label>
                            <div className="col-md-3">
                              <InputMask pattern={this.getInputPattern('double')} type="text" className="form-control" name="Moteur.CM3" value={this.getVehiculeProp("Moteur.CM3")} onChange={this.onChangeForm.bind(this)} />
                            </div>
                            <label className="col-md-3 control-label text-nowrap">Puiss. DIN (CV)</label>
                            <div className="col-md-3">
                              <InputMask pattern={this.getInputPattern("int")} type="text" className="form-control" name="Moteur.PuissanceCV" value={this.getVehiculeProp("Moteur.PuissanceCV")} onChange={this.onChangeForm.bind(this)} />
                            </div>
                          </div>

                        </div>
                      </div>
                    :null}

                    <br />

                    <div className="col-md-12 text-center">
                      <button className="btn btn-default" disabled={this.disabledButtonSave()} style={{ display: 'inline-block', marginLeft: 30 }}>
                        {this.state.saveInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} {this.state.isEdit ? "Valider les modifications" : "Créer le véhicule"}
                      </button>
                    </div>
                  </div>
                : null}
                
              </form>
            </div>

          </div>
        </div>
        <br/>
      </div>
    );
  }

  /**
   * Render si Agri
   */
  renderAgri(){
    return (
      <div className={'container-fluid form-vehicule '+ (localStorage.bypass_iFrame === 'true' ? 'iframe' : '')}>
        <div className='row'>
          <div className='col-md-12'>
            <div>
              <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>

{/****************************** Agri : Famille/Gamme **********************************/}
                <div className="form-group">
                  <div className="col-md-4">
                    <label className="col-md-4 control-label">Famille</label>
                    <div className="col-md-8">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Famille}</p>
                      :
                      <select style={{width:'100%'}} onChange={(e)=>this.selectFamille(e.target.value)}>
                        <option value=''>
                          {this.state.fetchFamillesInProgress ? 'Chargement des familles...' : 'Sélectionner une famille'}
                        </option>
                        {this.state.familles.map((famille) => {
                          return <option key={famille.IDFamille} value={famille.Descr}>{famille.Descr}</option>;
                        })}
                      </select>
                    }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-md-4 control-label">Gamme</label>
                    <div className="col-md-8">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Gamme}</p>
                      :
                      <select style={{width:'100%'}} name="Gamme" onChange={this.onChangeForm.bind(this)}>
                        <option value=''>
                          {this.state.fetchGammesInProgress ? 'Chargement des gammes...' : this.state.gammes.length ? 'Sélectionner une gamme' : ''}
                        </option>
                        {this.state.gammes.map((gamme) => {
                          return <option key={gamme.IDGamme} value={gamme.Descr}>{gamme.Descr}</option>;
                        })}
                      </select>
                    }
                    </div>
                  </div>
                </div>

{/****************************** Agri : Marque / Modèle / Version **********************************/}
                <div className="form-group">
                  <div className="col-md-4">
                      <label className="col-md-4 control-label">Marque</label>
                      <div className="col-md-8">
                      {this.state.isEdit ? 
                        <p className="form-control-static">{this.state.vehicule.Marque}</p>
                      :
                        this.state.saisieMarqueMan ?
                          <input type="text" name="Marque" className="form-control" ref="saisieMarqueMan" onChange={this.onChangeForm.bind(this)} />
                        :
                          <select style={{width:'100%'}} onChange={(e)=>this.selectMarque(e.target.value)}>
                            <option value=''>
                              {this.state.fetchMarquesInProgress ? 'Chargement des marques...' : 'Sélectionner une marque'}
                            </option>
                            <option value="_SAISIE-MANUELLE_">Saisir une marque</option>
                            {this.state.marques.map((marque) => {
                              return <option key={marque.Marque} value={marque.Marque}>{marque.Marque}</option>;
                            })}
                          </select>
                      }
                      </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-md-4 control-label">Modèle</label>
                    <div className="col-md-8">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Modele}</p>
                    :
                      this.state.saisieModeleMan ?
                        <input type="text" name="Modele" className="form-control" ref="saisieModeleMan" onChange={this.onChangeForm.bind(this)} />
                        : 
                        <select style={{width:'100%'}} onChange={(e)=>this.selectModele(e.target.value)}>
                          <option value=''>
                            {this.state.fetchModelesInProgress ? 'Chargement des modèles...' : this.state.modeles.length ? 'Sélectionner un modèle' : ''}
                          </option>
                          <option value="_SAISIE-MANUELLE_">Saisir un modèle</option>
                          {this.state.modeles.map((modele) => {
                            return <option key={modele.IDModele ? modele.IDModele : modele.Modele} value={modele.Modele}>{modele.Modele}</option>;
                          })}
                        </select>
                    }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="col-md-2 control-label">Version</label>
                    <div className="col-md-4">
                    {this.state.isEdit ? 
                      <p className="form-control-static">{this.state.vehicule.Version}</p>
                    :
                      <input type="text" name="Version" className="form-control" onChange={this.onChangeForm.bind(this)} />
                    }
                    </div>
                    <label className="col-md-2 control-label" key="annee_label">Année</label>
                    <div className="col-md-4" key="annee_input">
                      <input type="text" pattern="[0-9]+" name="Annee" value={this.getVehiculeProp("Annee")} className="form-control" onChange={this.onChangeForm.bind(this)} maxLength={4} />
                    </div>
                  </div>
                </div>

{/****************************** Agri : Champs spécifiques en fonction de la famille **********************************/}
                {this.state.fetchFieldsInProgress ? 
                    <div className="text-center" style={{borderTop:'1px solid #CBCBCB', padding:'20px'}}>
                      <i className="fa fa-spinner fa-spin fa-fw"/>
                    </div>
                  :this.state.fields ?
                    <div style={{borderTop:'1px solid #CBCBCB'}}>
                      <div style={{padding:'15px 0 0 15px'}}>Champs spécifiques "{this.state.vehicule.Famille}"</div>

                      {lodash.map(this.categoriesChamps, (libelle, categ) => {
                        if(this.state.fields[categ]){
                            return [
                                /*<div style={{padding:'15px 0 0 15px'}}>&gt; {libelle}</div>,*/
                                lodash.map(lodash.chunk(this.state.fields[categ], 3), (threeField, i) => {
                                    return (<div className="form-group" key={i}>
                                        {lodash.map(threeField, (field, j) => {
                                            if(field.API_Field) { //Champ non personnalisé 
                                              return (
                                                <div className="col-md-4" key={j}>
                                                  <label className="col-md-4 control-label">{field.Descr}</label>
                                                  <div className="col-md-8">
                                                    {field.Type === "list" || field.Type === "table" ? 
                                                      <select style={{width:'100%'}} name={field.API_Field} value={this.getVehiculeProp(field.API_Field)} onChange={this.onChangeForm.bind(this)}>
                                                        <option value=''></option>
                                                        {lodash.map(field.Values, (libelle, value) => {
                                                          return <option key={value} value={value}>{libelle}</option>
                                                        })}
                                                      </select>
                                                    :field.Type === "bool" ?
                                                      [<input key={"bool_input"+j} type="checkbox" id={"field-"+field.API_Field} name={field.API_Field} onChange={this.onChangeForm.bind(this)} checked={this.getVehiculeProp(field.API_Field)} />,
                                                      <label key={"bool_label"+j} className="label-box" htmlFor={"field-"+field.API_Field}>&nbsp;</label>]
                                                    :field.Type === "date" || field.Type === "immat" ?
                                                      <InputMask mask={this.getInputPattern(field.Type)} type="text" className="form-control" name={field.API_Field} value={this.getVehiculeProp(field.API_Field, field.Type)} onChange={this.onChangeForm.bind(this)} />
                                                    :
                                                      <input pattern={this.getInputPattern(field.Type)} type="text" className="form-control" name={field.API_Field} value={this.getVehiculeProp(field.API_Field)} onChange={this.onChangeForm.bind(this)} />
                                                    }
                                                  </div>
                                                </div>
                                              )
                                            }
                                            else { //Champ personnalisé
                                              return (
                                                <div className="col-md-4" key={j}>
                                                  <label className="col-md-4 control-label">{field.Descr}</label>
                                                  <div className="col-md-8">
                                                    <InputMask mask={this.getInputPattern(field.Type)} type="text" className="form-control" name={"ChampsPersonnalises."+field.IDFieldMat+".Value"} value={this.getChampPersonnaliseValue(field.IDFieldMat)} onChange={(e) => this.setChampPersonnalise(field.IDFieldMat, e.target.value)} />
                                                  </div>
                                                </div>
                                              )
                                            }
                                        })}
                                    </div>)
                                })
                            ]
                        }
                    })}
                    </div>
                  :null}

{/***********************Agri : Commentaire *******************/}
                {this.state.vehicule.Famille ? 
                  <div className="form-group">
                    <div className="col-md-8">
                      <label className="col-md-2 control-label" style={{paddingRight:'20px'}}>Commentaire</label>
                      <div className="col-md-10" style={{paddingLeft:'10px'}}>
                        <textarea className="form-control" name="Commentaire" style={{height:'50px'}} onChange={this.onChangeForm.bind(this)} value={this.state.vehicule.Commentaire}/>
                      </div>
                    </div>
                  </div>
                :null}

{/****************************** Financement **********************************/}
                {this.state.vehicule.Famille ? 
                  <div style={{borderTop:'1px solid #CBCBCB'}}>
                    <div style={{padding:'15px 0 0 15px'}}>Financement</div>
                    <div className="form-group">
                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Type de financement</label>
                        <div className="col-md-8">
                          <select style={{width:'100%'}} name="Financement.IDMatTypeF" onChange={this.onChangeForm.bind(this)} value={this.state.vehicule.Financement.IDMatTypeF ? this.state.vehicule.Financement.IDMatTypeF : ''}>
                            <option value=''>
                              {this.state.fetchTypesFinancementInProgress ? 'Chargement des types de financement...' : ''}
                            </option>
                            {this.state.typesFinancement.map((typeF) => {
                              return <option key={typeF.IDMatTypeF} value={typeF.IDMatTypeF}>{typeF.Descr}</option>;
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Durée (mois)</label>
                        <div className="col-md-8">
                          <input type="text" pattern="[0-9]+" className="form-control" name="Financement.DureeMois" value={this.getVehiculeProp("Financement.DureeMois")} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>
                    
                      <div className="col-md-4">
                        <label className="col-md-4 control-label">Terme</label>
                        <div className="col-md-8">
                          <InputMask mask="99/99/9999" className="form-control" name="Financement.Fin" value={this.getVehiculeProp("Financement.Fin", "date")} onChange={this.onChangeForm.bind(this)} />
                        </div>
                      </div>
                    </div>
                  </div>
                :null}
{/*********************************************************************************************** */}
                    <br />

                    <div className="col-md-12 text-center">
                      <button className="btn btn-default" disabled={this.disabledButtonSave()} style={{ display: 'inline-block', marginLeft: 30 }}>
                        {this.state.saveInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} {this.state.isEdit ? "Valider les modifications" : "Créer le "+(this.IsAgri ? "matériel" : "véhicule")}
                      </button>
                    </div>
      
              </form>
            </div>

          </div>
        </div>
        <br/>
      </div>
    );
  }

  /**
   * Render
   */
  render() {

    if(this.state.isEdit && (this.state.fetchTypesMaterielInProgress || this.state.fetchFieldsInProgress)) {
      return (
        <div className='col-md-12'>
            <div className="center-div" style={{paddingTop:'100px'}}>
                <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                <br/><br/>Chargement des informations...
            </div>
        </div>
      )
    }

    if(this.IsAgri)
      return this.renderAgri();
    else
      return this.renderDefault();
	
  }
}

export default VehiculeParcForm;
